<template>
  <AppSidebar />
  <div
    class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent"
  >
    <AppHeader />
    <div class="body flex-grow-1 px-3">
      <CContainer lg>
        <router-view />
      </CContainer>
    </div>
    <AppFooter />
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue-pro'
import AppHeader from '@/coreui//components/default/AppHeader.vue'
import AppSidebar from '@/coreui//components/default/AppSidebar.vue'
import AppFooter from '@/coreui//components/default/AppFooter.vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppSidebar,
    AppFooter,
    CContainer
  }
}
</script>
