import { createStore } from 'vuex'

import { coreui } from './coreui'
import { security } from './security'

export default createStore({
  modules: {
    coreui,
    security
  }
})
