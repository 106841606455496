import {
  cilBan,
  cilBoltCircle,
  cilCasino,
  cilEnvelopeClosed,
  cilGroup,
  cilHighlighter,
  cilHttps,
  cilLibraryAdd,
  cilLockLocked,
  cilLockUnlocked,
  cilLowVision,
  cilMagnifyingGlass,
  cilMenu,
  cilMediaSkipBackward,
  cilMediaSkipForward,
  cilMoon,
  cilPlus,
  cilSpeedometer,
  cilStorage,
  cilSun,
  cilThumbDown,
  cilThumbUp,
  cilTrash,
  cilListNumbered,
  cilChartLine,
  cilCalendar,
  cilUser
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilBan,
    cilBoltCircle,
    cilCasino,
    cilEnvelopeClosed,
    cilGroup,
    cilHighlighter,
    cilHttps,
    cilLibraryAdd,
    cilLockLocked,
    cilLockUnlocked,
    cilLowVision,
    cilMagnifyingGlass,
    cilMediaSkipBackward,
    cilMediaSkipForward,
    cilMoon,
    cilMenu,
    cilPlus,
    cilSpeedometer,
    cilStorage,
    cilSun,
    cilThumbDown,
    cilThumbUp,
    cilTrash,
    cilListNumbered,
    cilCalendar,
    cilChartLine,
    cilUser
  }
)
