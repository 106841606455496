<template>
  <CDropdown variant="nav-item" class="custom-nav-dropdown">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="true">
      <CIcon name="cil-user"/>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">

      <CDropdownHeader component="h6" class="py-2">

      </CDropdownHeader>

      <CDropdownItem disabled>
        {{name}} - {{ email }}
      </CDropdownItem>

      <CDropdownItem disabled class="lastlogin"  v-if="lastLoginAt!==''">
        Letzter Login am
        <br>
        {{momentDatum(lastLoginAt)}}
        um
        {{momentUhrzeit(lastLoginAt)}} Uhr
      </CDropdownItem>

      <CDropdownDivider/>

      <CDropdownItem class="boxNo">
        BoxNr: {{ boxNo }}
      </CDropdownItem>

      <CDropdownItem class="role">
        Rolle: {{ roles.replace('ROLE_','') }}
      </CDropdownItem>

      <CDropdownDivider/>
      <CDropdownItem>
        <CNavLink href="/profile/password">
          <CIcon name="cil-https" /> Mein Passwort Ändern
        </CNavLink>
      </CDropdownItem>

      <CDropdownItem>
        <CNavLink href="/security/logout">
          <CIcon name="cil-lock-locked" /> Logout
        </CNavLink>
      </CDropdownItem>

    </CDropdownMenu>
  </CDropdown>
</template>

<script>
const moment = require('moment')
require('moment/locale/de')

export default {
  name: 'coreui-components-default-account',
  computed: {
    user: function () {
      return this.$store.getters['security/user']
    },
    name: function () {
      return (this.user ? this.user.name : '')
    },
    email: function () {
      return (this.user ? this.user.email : '')
    },
    lastLoginAt: function () {
      return (this.user ? this.user.lastLoginAt : '')
    },
    boxNo: function () {
      return (this.user ? this.user.boxNo : '')
    },
    roles: function () {
      return (this.user ? this.user.roles[0] : '')
    }
  },
  methods: {
    momentDatum: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    momentUhrzeit: function (date) {
      return moment(date).format('HH:mm:ss')
    }
  }
}
</script>
