<template>
  <CHeader position="sticky" class="mb-4">

    <CContainer fluid>

      <CHeaderToggler class="ps-1" @click="toggleShowSidebar">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>

      <CHeaderNav class="mr-auto d-none d-lg-block d-xl-block">
        <CNavItem class="px-3 toggle-profimodus" @click="toggleProfiModus">
          <CIcon name="cil-media-skip-backward" v-if="!profiModus"/>
          <CIcon name="cil-media-skip-forward" v-if="profiModus"/>
        </CNavItem>
      </CHeaderNav>

      <!-- Hauptnavigation -->
      <CHeaderNav class="d-none d-md-flex me-auto">
        <span v-for="navitem in navigation" v-bind:key="navitem.name">

          <CNavItem v-if="hasNoChildren(navitem)">
            <router-link class="nav-link" :to="navitem.to">
              <CIcon :name="navitem.icon"/>
              <span class="d-md-down-none ms-2" v-if="!profiModus">{{navitem.name}}</span>
            </router-link>
          </CNavItem>

          <li v-else>
            <CDropdown variant="nav-item" class="custom-nav-dropdown">
              <CDropdownToggle placement="bottom-end" class="py-0" :caret="true">
                <CIcon :name="navitem.icon"/>
                <span class="d-md-down-none ms-2" v-if="!profiModus">{{navitem.name}}</span>
              </CDropdownToggle>

              <CDropdownMenu class="pt-0">
                <CDropdownHeader component="h6" class="py-2">
                  {{navitem.name}}
                </CDropdownHeader>

                <CDropdownItem v-for="navsubitem in navitem.items" v-bind:key="navsubitem.name">
                  <router-link class="nav-link" :to="navsubitem.to">
                    {{navsubitem.name}}
                  </router-link>
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </li>

        </span>
      </CHeaderNav>

      <CHeaderNav class="mr-0">
        <CNavItem class="d-none px-3 toggle-darkmode" v-on:click="toggleDarkMode()">
          <CIcon v-if="!darkMode" name="cil-sun"/>
          <CIcon v-else name="cil-moon"/>
        </CNavItem>
      </CHeaderNav>

      <AppAccount/>

    </CContainer>
  </CHeader>
</template>

<script>
import { logo } from '@/assets/brand/cows-logo-white.svg'
import nav from '@/router/_nav.js'
import AppAccount from '@/coreui/components/default/AppAccount'
import _has from 'lodash/has'

export default {
  name: 'AppHeader',
  components: {
    AppAccount
  },
  setup () {
    return {
      logo,
      nav
    }
  },
  computed: {
    darkMode () {
      return this.$store.getters['coreui/darkMode']
    },
    profiModus () {
      return this.$store.getters['coreui/profiModus']
    },
    navigation () {
      const navigation = nav.filter(n => {
        if (_has(n, 'allowed')) {
          if (n.allowed.length > 0) {
            if (!this.$store.getters['security/userHasRole'](n.allowed)) {
              return null
            }
          }
        }
        return n
      })
      return navigation
    }
  },
  methods: {
    toggleDarkMode () {
      this.$store.commit('coreui/TOGGLE_DARKMODE')
    },
    toggleProfiModus () {
      this.$store.commit('coreui/TOGGLE_PROFIMODUS')
    },
    hasNoChildren (item) {
      let result = true
      if (item.items) {
        if (item.items.length > 0) {
          result = false
        }
      }
      return result
    },
    toggleShowSidebar () {
      this.$store.commit('coreui/TOGGLE_SHOW_SIDEBAR')
    }
  }
}
</script>

<style lang="scss">
  li.toggle-profimodus, li.toggle-darkmode {
    cursor: pointer;
  }
</style>
