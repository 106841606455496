<template>
  <CSidebar
    position="fixed"
    :unfoldable="smallSidebar"
    :visible="showSidebar"
  >
    <CSidebarBrand>
      <img :src="logo" style="width:90%; height: auto; padding: 10px;" alt="Cows Online" class="sidebar-brand-full" />
      <img :src="logo" alt="Cows Online" class="sidebar-brand-narrow" />
    </CSidebarBrand>
    <AppSidebarNav />
    <div v-if="!smallSidebar" class="user-info d-none d-md-block p-2 overflow-hidden" style="font-size: 0.8rem;">
      <div class="row">
        <div class="col-12">
          <div class="row mt-2">
            <div class="col-12 col-md-3" style="font-size: 0.7rem;">
              Name:
            </div>
            <div class="col-12 col-md-9  fw-bold">
              {{ user.name }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 col-md-3" style="font-size: 0.7rem;">
              Mail:
            </div>
            <div class="col-12 col-md-9  fw-bold">
              {{ user.email }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 col-md-3" style="font-size: 0.7rem;">
              Rolle:
            </div>
            <div class="col-12 col-md-9  fw-bold">
              {{ user.roles[0].replace('ROLE_','') }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 col-md-3" style="font-size: 0.7rem;">
              BoxNr:
            </div>
            <div class="col-12 col-md-9  fw-bold">
              {{ user.boxNo }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 col-md-3" style="font-size: 0.7rem;">
              Login:
            </div>
            <div class="col-12 col-md-9 fw-bold">
              {{ user.lastLoginAt }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="toggleSmallSidebar"
    />
  </CSidebar>
</template>

<script>
import { AppSidebarNav } from './AppSidebarNav'
import logo from '@/assets/brand/logo.png'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav
  },
  setup () {
    return {
      logo
    }
  },
  computed: {
    user: function () {
      return this.$store.getters['security/user']
    },
    smallSidebar () {
      return this.$store.getters['coreui/smallSideBar']
    },
    showSidebar () {
      return this.$store.getters['coreui/showSideBar']
    }
  },
  methods: {
    toggleSmallSidebar () {
      this.$store.commit('coreui/TOGGLE_SMALL_SIDEBAR')
    }
  }
}
</script>
