import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import _foreach from 'lodash/forEach'

import DefaultLayout from '@/coreui/layouts/Default'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: { requiresAuth: true, allowed: ['ROLE_USER', 'ROLE_SUPERUSER', 'ROLE_EDITOR'] }
      }
    ]
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import('@/views/Impressum'),
    meta: { requiresAuth: false }
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () => import('@/views/Datenschutz'),
    meta: { requiresAuth: false }
  },
  {
    path: '/security',
    name: 'Security',
    redirect: '/security/login',
    component: {
      render () {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/Login.vue')
      },
      {
        path: 'logout',
        name: 'Logout',
        component: () => import('@/views/Logout.vue')
      },
      {
        path: 'password',
        name: 'Password',
        component: () => import('@/views/Password.vue')
      }
    ]
  },
  {
    path: '/error',
    name: 'Error',
    redirect: '/error/403',
    component: {
      render () {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: '403',
        name: 'Page403',
        component: () => import('@/views/Page403.vue')
      }
    ]
  },
  {
    path: '/profile',
    redirect: '/profile/password',
    name: 'Profil',
    component: DefaultLayout,
    children: [
      {
        path: 'password',
        name: 'Mein Passwort Ändern',
        component: () => import('@/views/Profile/Password'),
        meta: { requiresAuth: true, allowed: ['ROLE_USER', 'ROLE_SUPERUSER', 'ROLE_EDITOR'] }
      }
    ]
  },
  {
    path: '/benutzer',
    redirect: '/benutzer/uebersicht',
    name: 'Benutzer',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Benutzerverwaltung - Übersicht',
        component: () => import('@/views/Benutzer/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN', 'ROLE_SUPERUSER'] }
      },
      {
        path: 'neu',
        name: 'Benutzerverwaltung - Neuer Benutzer',
        component: () => import('@/views/Benutzer/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN', 'ROLE_SUPERUSER'] }
      },
      {
        path: 'passwort/:uuid',
        name: 'Mitarbeiter Passwort ändern',
        component: () => import('@/views/Benutzer/Passwort'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN', 'ROLE_SUPERUSER'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'Mitarbeiter Editieren',
        component: () => import('@/views/Benutzer/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN', 'ROLE_SUPERUSER'] }
      }
    ]
  },
  {
    path: '/daten',
    redirect: '/daten/uebersicht',
    name: 'Daten',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Daten - Neuer Datensatz',
        component: () => import('@/views/Daten/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_USER', 'ROLE_SUPERUSER', 'ROLE_EDITOR'] }
      },
      {
        path: 'neu',
        name: 'Daten - Neuer Datensatz',
        component: () => import('@/views/Daten/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_USER', 'ROLE_SUPERUSER', 'ROLE_EDITOR'] }
      },
      {
        path: 'exportieren',
        name: 'Daten - Exportieren',
        component: () => import('@/views/Daten/Exportieren'),
        meta: { requiresAuth: true, allowed: ['ROLE_USER', 'ROLE_SUPERUSER', 'ROLE_EDITOR'] }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () { return { top: 0 } }
})

//   ____  _____ _____ ____  _     ___ _   _ _  __   ___  _   _ _   _ _____      _   _   _ _____ _   _
//  |  _ \| ____| ____|  _ \| |   |_ _| \ | | |/ /  / _ \| | | | \ | | ____|    / \ | | | |_   _| | | |
//  | | | |  _| |  _| | |_) | |    | ||  \| | ' /  | | | | |_| |  \| |  _|     / _ \| | | | | | | |_| |
//  | |_| | |___| |___|  __/| |___ | || |\  | . \  | |_| |  _  | |\  | |___   / ___ \ |_| | | | |  _  |
//  |____/|_____|_____|_|   |_____|___|_| \_|_|\_\  \___/|_| |_|_| \_|_____| /_/   \_\___/  |_| |_| |_|
//
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['security/isAuthenticated']) {
      next()
    } else {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
})
//   ____   ___  _     _____ ____
//  |  _ \ / _ \| |   | ____/ ___|
//  | |_) | | | | |   |  _| \___ \
//  |  _ <| |_| | |___| |___ ___) |
//  |_| \_\\___/|_____|_____|____/
//
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.allowed)) {
    if (store.getters['security/hasRole']('ROLE_ADMIN')) {
      next()
    } else {
      let hasRole = false
      _foreach(to.meta.allowed, function (role) {
        if (store.getters['security/hasRole'](role)) {
          hasRole = true
        }
      })
      if (!hasRole) {
        next({ name: 'Page403' })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

//   ____   _    ____ _____   _____ ___ _____ _     _____ ____
//  |  _ \ / \  / ___| ____| |_   _|_ _|_   _| |   | ____/ ___|
//  | |_) / _ \| |  _|  _|     | |  | |  | | | |   |  _| \___ \
//  |  __/ ___ \ |_| | |___    | |  | |  | | | |___| |___ ___) |
//  |_| /_/   \_\____|_____|   |_| |___| |_| |_____|_____|____/
//
router.beforeEach((to, from, next) => {
  document.title = to.name + ' | fidia Pharma GmbH'
  next()
})

export default router
