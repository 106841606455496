<template>
  <div class="text-center mt-5">
    2023 © fidia Pharma GmbH | <a href="/impressum">Impressum</a> | <a href="/datenschutz">Datenschutz</a>
  </div>

</template>

<script>
export default {
  name: 'AppFooter',
  components: {
  }
}
</script>
